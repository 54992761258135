<template>
  <Card class="card-item">
    <template slot="title">
      {{data.qbsAccountName}}
    </template>
    <div class="cu_cardcell">
      <span>{{data.qbsAccountPhone}}</span>
      <span style="margin: 0 8px;">·</span>
      <span>{{renderPhon(data)}}</span>
    </div>
    <div class="source">
      <CardCell
        label="归属地 "
        :value="data.qbsPhoneLocalCity"
      />
      <div>{{data.origin}}</div>
    </div>
    <template slot="footer">
      <span>{{handletime(data.operatingFromTime)}}</span>
      <Button
        v-if="state === 0"
        round
        type="info"
        size="small"
        style="width: 80px;"
        @click="showPopup(data)"
        v-auth="'OppSend-Distribution'"
      >分配</Button>
    </template>
  </Card>
</template>
<script>
import { Button } from 'vant'
import Card from '_c/card'
import CardCell from '_c/card/cardCell'
import dayjs from 'dayjs'
export default {
  components: {
    Button,
    Card,
    CardCell
  },
  props: {
    data: {
      type: Object
    }
  },
  methods: {
    showPopup (cardInfo) {
      console.log(cardInfo, 'cardInfo')
      //传值分配显示选项
      cardInfo.Distribution = '0'
      this.$router.push({
        path: '/taoGuestPoolAllot',
        query: cardInfo
      })
    },
    // 处理时间
    handletime (time) {
      return dayjs(time).format('YYYY/MM/DD HH:mm:ss')
    },
    // 手机号归属地
    renderPhon (params) {
      return params.qbsPhoneLocalProvince + '/' + params.qbsPhoneLocalCity
    }
  },
  inject: ['getState'],
  computed: {
    state () {
      return this.getState()
    }
  }
}
</script>
<style lang='less' scoped>
@import "@/style/common.less";
.card-item {
  font-size: 24px;
}
.source {
  display: flex;
  justify-content: space-between;
}
.card-info {
  display: flex;
  align-items: center;

  .name {
    width: 200px;
    margin-right: 20px;
  }

  .tag {
    display: inline-block;
    padding: 0 10px;
    border: 1px solid;
    border-radius: 8px;
    color: @color-blue;
    font-size: 12px;
    height: 42px;
    line-height: 42px;
  }
}
.cu_cardcell {
  font-size: 24px;
  line-height: 34px;
  color: #666;
}
</style>