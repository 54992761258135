<template>
  <div
    class="footer"
    v-if="dataList.length"
  >
    <div class="footer-wrap space-between">
      <div>
        <Button
          @click="$emit('on-select')"
          size="small"
          type="info"
          plain
          round
        >全选</Button>
        <Button
          @click="$emit('on-cancel')"
          size="small"
          type="info"
          plain
          round
        >取消全选</Button>
      </div>
      <div>
        <Button
          size="small"
          type="info"
          @click="hand(selection,dataList)"
        >分配({{ selection.length }})</Button>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, Toast } from 'vant'
export default {
  components: {
    Button
  },
  methods: {
    //线索批量分配
    hand (selection, dataList) {
      let res = ''
      selection.forEach((item, index) => {
        res += dataList[item].eosLeadesAssignmentHistoryId + ','
      })
      this.multiple = res.substring(0, res.length - 1)
      const cardInfo = { Distribution: '0', eosLeadesAssignmentHistoryId: this.multiple }
      // 如果没有选择，无法分配
      if (!this.multiple) {
        Toast('请选择')
        return
      }
      this.$router.push({
        path: '/taoGuestPoolAllot',
        query: cardInfo
      })
    }
  },
  props: {
    selection: {
      type: Array,
      default: () => []
    },
    dataList: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<style lang="less" scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 182px;
  line-height: 98px;
  font-size: 32px;
  background-color: #2b2b31;

  .footer-wrap {
    padding: 24px;
    height: 100%;
    align-items: flex-start;

    /deep/ .van-button--plain {
      border: 0;
      background-color: transparent;
      margin-right: 15px;
    }

    & > div {
      line-height: 1;
    }

    & > div:last-child /deep/ .van-button {
      width: 148px;
      border-radius: 8px;
    }
  }
}
</style>