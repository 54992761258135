<template>
  <App
    title="线索下发分配"
    :border="false"
    left-arrow
    @click-left="onNavBarClickLeft"
    @click-right="onNavBarClickRight"
  >
    <div
      slot="right"
      v-auth="'LeadsSend-MassDistribution'"
      style="color:#FB7E01;"
    >
      <a>{{ state === 0 ? '批量分配' : '取消分配' }}</a>
    </div>
    <div class="container">
      <List
        ref="list"
        v-model="selectionList"
        :dataList="dataList"
        @on-load="onLoad"
      />
    </div>
    <Footer
      v-if="state === 1"
      :selection="selectionList"
      :dataList="dataList"
      @on-select="toggleAll(true)"
      @on-cancel="toggleAll(false)"
    />
  </App>
</template>
<script>
import List from './components/list'
import Footer from './components/footer'
import { leadsSendList, getSource } from '@/api/chargequery'
import { Toast } from 'vant'
export default {
  name: 'clueAllot',
  components: {
    List,
    Footer
  },
  provide () {
    return {
      getState: () => this.state
    }
  },
  data () {
    return {
      state: 0, // 0: 默认值 1: 批量分配
      isLoading: false,
      dataList: [],
      selectionList: [],
      searchOptions: {}
    }
  },
  watch: {
    state (value) {
      if (value === 0) {
        this.selectionList = []
      }
    }
  },
  methods: {
    onLoad () {
      // todo
      this.isLoading = true
      this.getData().then(() => {
        this.$refs.list.list.loading = true
        this.isLoading = false
      })

    },
    onNavBarClickLeft () {
      this.$router.go(-1)
    },
    onNavBarClickRight () {
      if (this.isLoading) return
      this.state = this.state === 0 ? 1 : 0
    },
    toggleAll (selectAll) {
      this.$refs.list.toggleAll(selectAll)
    },
    // 获取线索来源
    async getSource () {
      const res = await getSource()
      if (res.code === '000000') {
        console.log('getSource', res.data)
        this.clueSource = res.data.map(e => {
          return { 'code': e.threelevelCode, 'text': e.threelevelName }
        })
      } else {
        Toast(res.msg)
      }
    },
    // 获取数据
    async getData () {
      /* Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true
      }) */
      const { data, code } = await leadsSendList(
        {
          page: 1,
          size: 200
        },
        { status: '0' }
      )
      Toast.clear()
      if (code === '000000') {
        if (data.records.length === 0) return Toast('No data')
        // 如果没有id，添加自定义id，避免批量分配全选问题
        data.records.forEach((item, index) => {
          if (!item.id) item.id = index
          this.clueSource.forEach(el => {
            if (item.origin === el.code) {
              item['origin'] = el.text
            }
          })
        })
        this.dataList = data.records
      } else {
        Toast('请求数据失败')
      }
    },
    async init () {
      this.dataList = []
      this.state = 0
      this.selectionList = []
      await this.getSource()
      await this.getData()
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="less" scoped>
</style>